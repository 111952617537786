<template>
    <div>
        <el-form label-width="140px" :model="form" :rules="rules" ref="ruleForm">
            <el-form-item label="実行時間" prop="cron">
                <el-select v-model="form.cron" placeholder="実行時間" style="width: 100%">
                    <template v-for="item in cronList">
                        <el-option :label="item.label" :value="item.value" :key="item.val"></el-option>
                    </template>
                </el-select>
                <!--        <el-input v-int v-model="form.cron" type="text" placeholder="直ちに執行" style="width: 180px;"></el-input>-->
            </el-form-item>
            <el-form-item label="備考" style="margin-top: 15px">
                <el-input v-model="form.remark" type="textarea" :show-word-limit="true" maxlength="500"
                          rows="4"></el-input>
            </el-form-item>
        </el-form>
        <span class="dialog-footer">
            <el-button class="btn-width-big" @click="cancel" icon="el-icon-back">キャンセル</el-button>
            <el-button class="btn-width-big" type="success" @click="save" icon="el-icon-check">保存</el-button>
        </span>
    </div>
</template>

<script>
    import Crontab from '@/views/components/Crontab'

    export default {
        components: {Crontab},
        name: "scheduledEdit",
        props: ['data'],
        data() {
            return {
                cronExpression: '',
                // 入力の式
                expression: "",
                openCron: false,
                cronList: [],
                form: {
                    cron: '',
                    remark: ''
                },
                rules: {
                    cron: [
                        {required: true, message: '直ちに執行', trigger: 'blur'}
                    ]
                },
            }
        },
        mounted() {
            this.cronList = this.$util.cornList();
            this.form.cron = this.data.cronExpression
            this.form.remark = this.data.remark
        },
        methods: {
            /** 確定後バックパス値 */
            crontabFill(value) {
                this.form.cron = value;
            },
            /** cron開く **/
            openCronClick() {
                this.expression = this.form.cron;
                this.openCron = true;
            },
            cancel() {
                this.$emit('cancel')
            },
            async save() {
                let param = this.data;
                param['cronExpression'] = this.form.cron
                param['remark'] = this.form.remark
                param['status'] = this.data.status ? '0' : '1'
                let res = await this.$http.sys.jobUpdate(param);
                if (res.code === 200) {
                    this.$message.success(res.msg)
                }
                this.$emit('save')
            },
        }
    }
</script>

<style lang="scss" scoped>
  .del-box {
    display: inline-block;
    color: rgba(217, 0, 27, 0.996078431372549) !important;;
    margin-left: 8px;
    cursor: pointer;
  }

  .add-box {
    display: block;
    color: rgba(24, 144, 255, 1) !important;

    span {
      cursor: pointer;
    }
  }

  .el-form-item {
    text-align: left;
  }

  .el-form-item:last-child {
    margin-bottom: 0;
  }

  .dialog-footer {
    display: block;
    margin-top: 20px;
  }
</style>
